/*PROPERTIES*/
/*HOME/LOGIN PAGES*/

#root {
  /* background: url("../images/background.png") no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  }

.transparentBackground {
  background-color: transparent !important;
  border: none !important;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4em;
  height: 4em;
  z-index: 1;
}

.header-left {
  margin-top: 1em;
  margin-left: 0.5em; }

.hero-text-container {
  padding-bottom: 1em;
  padding-top: 1em;
  margin: auto; }
  .hero-text-container img.main-hero-image, .hero-text-container img.main-hero-image:after {
    zoom: 45%; }
  .hero-text-container .thanks-main-title {
    color: #0000ff;
    font-family: "Oswald", sans-serif;
    font-weight: bold;
    padding: 0 1em 0 1em; }
    .hero-text-container .thanks-main-title h1 {
      text-shadow: none;
      font-size: 9vmax;
      line-height: 1em; }
    .hero-text-container .thanks-main-title .intro-button-wrapper {
      text-align: center; }
  .hero-text-container .login-main-subtitle, .hero-text-container .thanks-main-subtitle {
    color: white;
    text-align: center;
    font-size: 6vmax;
    font-family: "Oswald", sans-serif;
    font-weight: 600; }
  .hero-text-container .login-main-subtitle {
    padding: 0 1em 0 1em; }
  .hero-text-container .thanks-main-subtitle {
    padding: 0 0.5em 0 0.5em; }

.main-container-login .btn-info-icon {
  background: none;
  float: right;
  border: none;
  outline: none; }
  .main-container-login .btn-info-icon #info-icon {
    height: 2em; }

.main-container-login .button-content-container {
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .main-container-login .button-content-container h2 {
    color: white;
    float: left;
    font-family: "Oswald", sans-serif;
    line-height: initial;
    margin-bottom: 0;
    margin-right: 0.5em; }
  .main-container-login .button-content-container .button-image {
    float: left; }

.main-container-login .number-button {
  color: #000000;
  float: left;
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  margin-bottom: 0;
  width: 100%;
  background-color: transparent;
  border: none;
  outline-width: 0px; }

.main-container-login .button-container-login-number {
  position: fixed;
  bottom: 5.5em; }

.main-container-login .button-content-container-number {
  display: flex;
  flex-direction: row;
  justify-content: center; }

.main-container-login .button-item-container-number {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 5.5em;
  height: 3.1em; }

.main-container-login .button-container-login {
  position: fixed;
  bottom: 0; }

.main-container-login .button-item-container {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 5.5em; }

.lock-background {
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: fixed;
  overscroll-behavior-y: none; }

#scratch-image {
  width: 280px;
  height: 380px; }

/*.flex-container-home .loader {*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*  width: 4em;*/
/*  height: 4em; }*/

/*.flex-container-home .loader hr {*/
/*  border: 0;*/
/*  margin: 0;*/
/*  width: 40%;*/
/*  height: 40%;*/
/*  position: absolute;*/
/*  border-radius: 50%;*/
/*  animation: spin 2s ease infinite; }*/

/*.flex-container-home .loader :first-child {*/
/*  background: rgba(0, 0, 255, 0.9);*/
/*  animation-delay: -1.5s; }*/

/*.flex-container-home .loader :nth-child(2) {*/
/*  background: rgba(0, 0, 255, 0.9);*/
/*  animation-delay: -1s; }*/

/*.flex-container-home .loader :nth-child(3) {*/
/*  background: rgba(0, 0, 255, 0.9);*/
/*  animation-delay: -0.5s; }*/

/*.flex-container-home .loader :last-child {*/
/*  background: rgba(0, 0, 255, 0.9); }*/

@keyframes spin {
  0%, 100% {
    transform: translate(0); }
  25% {
    transform: translate(160%); }
  50% {
    transform: translate(160%, 160%); }
  75% {
    transform: translate(0, 160%); } }

.flex-container-home .scratch-container-home {
  width: 50vmax !important;
  max-width: 280px; }
  .flex-container-home .scratch-container-home .scratch-image-container .scratch-image {
    width: 100%;
    height: 100%; }
    .flex-container-home .scratch-container-home .scratch-image-container .scratch-image .ScratchCard__Container {
      width: 100% !important; }
      .flex-container-home .scratch-container-home .scratch-image-container .scratch-image .ScratchCard__Container .ScratchCard__Canvas {
        width: 100% !important;
        height: 100% !important; }

.flex-container-home .flex-header-home .header-right .btn-logout {
  background-color: transparent;
  background-image: none;
  border-color: #0000ff;
  color: #0000ff;
  font-family: "Oswald", sans-serif;
  margin-top: 1em;
  margin-right: 0.5em; }

.flex-container-home .flex-content-container-home .intro-container-home, .flex-container-home .flex-content-container-home .thanks-container-home {
  margin: auto;
  /* padding-bottom: 4em;  */
}

.flex-container-home .scratch-container-home {
  margin: 2em auto auto; }
  .flex-container-home .scratch-container-home .scratch-header-home h2 {
    font-family: "Oswald", sans-serif;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 2em;
    line-height: 1; }

.flex-container-home .button-wrapper {
  display: flex;
  justify-content: center; }

.flex-container-home .button-item-container {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 4em; }
  .flex-container-home .button-item-container .btn-scratcher {
    border-radius: 0 !important;
    bottom: 0;
    font-size: 1.8em;
    font-weight: 800;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    font-family: "Oswald", sans-serif;
    color: #000000;
    background-color: #0000ff;
    text-transform: uppercase; }

/*MODAL*/
.modal {
  display: flex !important;
  vertical-align: middle; }
.modal .modal-dialog {
  margin: auto; }
.modal .modal-header {
  display: initial;
  text-align: center;
  border-bottom: none;
  padding-bottom: 0;
  padding-top: 2em; }
.modal .modal-header .modal-title {
  display: inline-block;
  color: #000000;
  font-family: "Oswald", sans-serif; }
.modal .modal-body {
  padding: 0;
  font-family: "Oswald", sans-serif; }
.modal .modal-body .question-box.question-form {
  border: none;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 2em; }
.modal .modal-body .question-box.question-form .form-control {
  height: 3em; }
.modal .modal-body .question-box.question-form .btn.btn-default.btn-admin {
  font-family: "Oswald", sans-serif;
  background: #0000ff;
  color: #000000;
  width: 65%;
  height: 3em;
  border: none; }
